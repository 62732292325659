<template>
  <dx-util-popup
    ref="locationMultipleSetterPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="false"
    height="auto"
    :width="400"
    :position="'center'"
    @shown="locationMultipleSetterPopupShown"
    @hidden="locationMultipleSetterPopupHidden"
  >
    <div>
      <dx-util-text-box ref="locationBox" v-model="location">
        <dx-util-validator>
          <dx-util-required-rule message="Location is required" />
        </dx-util-validator>
      </dx-util-text-box>
    </div>
    <div class="text-right mt-1">
      <dx-util-button text="Cancel" type="danger" style="width:100px" class="mr-1" @click="closePopup" />
      <dx-util-button text="Save" type="success" style="width:100px" @click="handleSubmit" />
    </div>
  </dx-util-popup>
</template>

<script>
import packageScanService from '@/http/requests/inbound/packageScanService'
// import { Notify } from '@/@robustshell/utils'

export default {
  components: {
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    recordIds: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      popupTitle: '',
      location: '',
    }
  },
  computed: {
    locationMultipleSetterPopup() {
      return this.$refs.locationMultipleSetterPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.locationMultipleSetterPopup.show()
      },
    },
  },
  methods: {
    locationMultipleSetterPopupShown() {
      this.popupTitle = 'Set Location for multiple items'
      this.$refs.locationBox.instance.focus()
    },
    locationMultipleSetterPopupHidden() {
    // Clear form when popup is closed
      this.location = ''
    },
    closePopup() {
      this.locationMultipleSetterPopup.hide()
    },
    async handleSubmit(e) {
      const validationResult = e.validationGroup.validate()
      if (validationResult.isValid) {
        const payload = {
          ids: this.recordIds,
          location: this.location,
        }
        await packageScanService.assignLocation(payload)
        this.$emit('emit-location-updated')
        this.closePopup()
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
